import {
  Box,
  Button,
  ButtonProps,
  Divider,
  MenuItem,
  MenuList,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import tokens from '@verifime/design-tokens';
import { useState } from 'react';
import { ColoredIcon, getColorByName, TColorName, TIconName } from './ColoredIconAndChip';

export type TActions<T extends string | Record<string, unknown>> = {
  [action in T extends string ? string : keyof T]: {
    iconName: TIconName;
    colorName: TColorName;
    onActionClick?: (action: T extends string ? string : keyof T) => void;
  };
};

export default function ActionsButton<T extends string | Record<string, unknown>>({
  buttonName,
  actions,
  ...props
}: Readonly<
  {
    buttonName: string;
    actions: TActions<T>;
  } & Omit<ButtonProps, 'onClick'>
>) {
  const [isShowActions, setIsShowActions] = useState(false);
  const theme = useTheme();
  return (
    <Box sx={{ position: 'relative' }}>
      <Button variant="contained" onClick={() => setIsShowActions(!isShowActions)} {...props}>
        <Typography variant="button">{buttonName}</Typography>
        <ColoredIcon iconName="keyboardArrowDownRounded" />
      </Button>

      {isShowActions && Object.keys(actions || {}).length > 0 && (
        <Paper
          sx={{
            position: 'absolute',
            minWidth: '150px',
            width: '80%',
            top: '50%',
            right: 0,
            zIndex: 2,
          }}
        >
          <MenuList sx={{ padding: `${tokens.spacingXs} 0` }}>
            {(Object.entries(actions) as [keyof TActions<T>, TActions<T>[keyof TActions<T>]][]).map(
              ([action, settings], index) => {
                return (
                  <MenuItem
                    key={action + index}
                    onClick={() => {
                      settings.onActionClick?.(action);
                      setIsShowActions(false);
                    }}
                  >
                    <Stack direction="row" gap={tokens.spacingXs}>
                      <ColoredIcon
                        sx={{ flex: 1 }}
                        iconName={settings.iconName}
                        colorName={settings.colorName}
                        isCompact
                      />
                      <Typography
                        sx={{
                          flex: 2,
                          color: getColorByName(settings.colorName)(theme).fillColor,
                        }}
                      >
                        {action}
                      </Typography>
                    </Stack>
                    <Divider />
                  </MenuItem>
                );
              },
            )}
          </MenuList>
        </Paper>
      )}
    </Box>
  );
}
