import { Box, Stack, Theme, useMediaQuery, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import {
  NoDataFound,
  StatusChip,
  TTrafficLightStatus,
  VerificationStatus,
} from '@verifime/components';
import tokens from '@verifime/design-tokens';
import { formatToDDMMMYYYY } from '@verifime/utils';

export type TKycRow = {
  id: number;
  identitySource?: string;
  expires?: string;
  lastUpdated?: string;
  status?: string;
  documentCaptureStatus?: TTrafficLightStatus;
  documentDataMatchStatus?: TTrafficLightStatus;
};
export type TKycRows = TKycRow[];
type TColumnMapping = { [key in keyof Omit<Required<TKycRow>, 'id'>]: GridColDef };

export default function KYC({
  rows,
  columnNames,
}: Readonly<{
  rows: TKycRows;
  columnNames: (keyof TColumnMapping)[];
}>) {
  const theme = useTheme();

  const getRowClassName = (params: GridRowParams) => {
    return params.row.status === 'Expired' ? 'highlight-row-text' : '';
  };

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const flex = isDesktop && 1;
  const MAPPINGS: TColumnMapping = {
    identitySource: {
      field: 'identitySource',
      headerName: 'Identity Source',
      flex,
      sortable: false,
    },
    expires: {
      field: 'expires',
      headerName: 'Expires',
      flex,
      sortable: false,
      renderCell: ({ value, row }) => {
        if (row.status === 'Expired') {
          return (
            <Stack direction="row" gap={tokens.spacing2xs} alignItems="center">
              {formatToDDMMMYYYY(value)} <StatusChip status="Expired" />
            </Stack>
          );
        }
        return formatToDDMMMYYYY(value);
      },
    },
    lastUpdated: {
      field: 'lastUpdated',
      headerName: 'Last Updated',
      flex,
      sortable: false,
      renderCell: ({ value }) => formatToDDMMMYYYY(value),
    },
    status: {
      field: 'status',
      headerName: 'Status',
      flex,
      sortable: false,
      renderCell: ({ value }) => {
        return <VerificationStatus status={value} />;
      },
    },
    documentCaptureStatus: {
      field: 'documentCaptureStatus',
      headerName: 'Document Capture',
      flex,
      sortable: false,
      renderCell: ({ value }) => {
        return (
          <Box>
            <StatusChip status={value} />
          </Box>
        );
      },
    },
    documentDataMatchStatus: {
      field: 'documentDataMatchStatus',
      headerName: 'Document Data Match',
      flex,
      sortable: false,
      renderCell: ({ value }) => {
        return (
          <Box>
            <StatusChip status={value} />
          </Box>
        );
      },
    },
  };

  if (columnNames.length === 0) {
    throw new Error('Column specified cannot be empty.');
  }
  const columns = columnNames.map((columnMapping) => MAPPINGS[columnMapping]);
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableDensitySelector
      disableVirtualization
      disableRowSelectionOnClick
      autoHeight
      getRowClassName={getRowClassName}
      sx={{
        border: '0',
        '& .highlight-row-text': {
          color: (theme: Theme) => theme.palette.error.main,
        },
      }}
      slots={{
        noRowsOverlay: NoDataFound,
      }}
    />
  );
}
