import { Stack } from '@mui/material';
import { api } from '@verifime/api-definition';
import {
  enqueErrorNotification,
  MultiFileUploader,
  TFileUploadResult,
  TFileUploadStatus,
} from '@verifime/components';
import tokens from '@verifime/design-tokens';
import { FileType } from '@verifime/utils';
import { useState } from 'react';
import ClientLogoByOrganisationCode from './ClientLogoByOrganisationCode';

const UploadLogo = ({
  organisationCode,
  onLogoUploadDone,
}: {
  organisationCode: string;
  onLogoUploadDone: (fileUploadStatus: TFileUploadStatus) => void;
}) => {
  const handleUploadFile = async (
    fileUploadStatus: TFileUploadStatus,
  ): Promise<TFileUploadResult> => {
    if (!organisationCode) {
      enqueErrorNotification('Please enter an organisation code.');
      return;
    }

    try {
      const fileType = fileUploadStatus.fileType.split('/')[1].toUpperCase();
      const res = await api.postV1logoorganisationCodeOrganisationCode(
        // @ts-ignore
        { file: fileUploadStatus.file, fileType },
        { params: { organisationCode } },
      );

      return {
        fileUploadUrl: res.url,
        fileId: fileUploadStatus.fileId,
        isSuccess: true,
        isRemoveFileUploadResult: true,
      };
    } catch (err) {
      enqueErrorNotification(
        'Something went wrong while uploading the logo, please check your inputs.',
      );
      return {
        fileUploadUrl: fileUploadStatus.fileUploadUrl,
        fileId: fileUploadStatus.fileId,
        isSuccess: false,
      };
    }
  };

  return (
    <>
      <MultiFileUploader
        label="Upload the logo"
        fileTypes={[FileType.PNG, FileType.JPG, FileType.JPEG]}
        existingUploadedFiles={[]}
        isDetectSameFileSelection
        isMultiple={false}
        onRetrieveFileUploadUrls={() => {
          return Promise.resolve([]);
        }}
        onSingleUploadDone={onLogoUploadDone}
        onUploadFile={handleUploadFile}
        onDeleteFile={(fileToDelete) => {}}
        onChange={(filesUploadStatus) => {}}
        onAllUploadsDone={(filesUploadStatus) => {}}
        isDraggable={true}
      />
    </>
  );
};

export default function LogoUploader({ organisationCode }: Readonly<{ organisationCode: string }>) {
  const [uploadTime, setUploadTime] = useState<number | null>(null);
  return (
    <Stack gap={tokens.spacingBase}>
      <UploadLogo
        key={organisationCode}
        organisationCode={organisationCode}
        onLogoUploadDone={(fileUploadStatus) => {
          // Upload succeeded
          if (fileUploadStatus.uploadStatus === 'Complete') {
            setUploadTime(new Date().getTime());
          }
        }}
      />
      <ClientLogoByOrganisationCode
        key={organisationCode + uploadTime}
        organisationCode={organisationCode}
        title=""
      />
    </Stack>
  );
}
