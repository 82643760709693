import { ChipProps } from '@mui/material';
import StatusChip, { TStatusChipsVariant } from '../Status/StatusChip';
import { TRiskLevels } from './types';

export type RiskLevelChipProps = {
  riskLevel: TRiskLevels;
  variant?: TStatusChipsVariant;
} & Omit<ChipProps, 'variant'>;

export default function RiskLevelChip({
  riskLevel,
  variant = 'label-only',
  ...props
}: RiskLevelChipProps) {
  const riskLevelLabel = riskLevel === 'Pending' ? 'Verification Pending' : riskLevel;
  return <StatusChip {...props} status={riskLevel} label={riskLevelLabel} variant={variant} />;
}
