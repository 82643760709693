import { api, apiSchemas } from '@verifime/api-definition';
import {
  TrafficLight as TrafficLightComponent,
  TTrafficLightData,
  TTrafficLightOperations,
} from '@verifime/components';
import { useEffect, useState } from 'react';
import { z } from 'zod';

const TRAFFIC_LIGHT_NAME_MAPPING: { [key in keyof TTrafficLightData]: string } = {
  Capture: 'Document Capture',
  DataMatch: 'Document Data Match',
  Liveness: 'Face Check: Liveness',
  PhotoMatch: 'Face Check: Photo Match',
};

type TTrafficLightResponse = z.infer<typeof apiSchemas.VerificationPipelineResponse>;
type TTrafficLightResponseValues = Array<
  z.infer<typeof apiSchemas.VerificationPipelineResponseDocument> &
    z.infer<typeof apiSchemas.VerificationPipelineResponseFaceCheck>
>;

const extractTrafficLightData = (
  trafficLightResponse: TTrafficLightResponse,
): TTrafficLightData => {
  const result = (Object.values(trafficLightResponse) as TTrafficLightResponseValues).reduce(
    (tally, values) => {
      const attachedName = Object.entries(values.pipeline).reduce((tally, [k, v]) => {
        return {
          ...tally,
          [k]: { ...v, name: TRAFFIC_LIGHT_NAME_MAPPING[k as keyof TTrafficLightData] },
        };
      }, {});
      return { ...tally, ...attachedName };
    },
    {},
  );
  return result as TTrafficLightData;
};

export default function TrafficLight({
  customerId,
  operations,
  onDataChange,
}: Readonly<{
  customerId: string;
  operations?: TTrafficLightOperations;
  onDataChange?: (trafficLightData: TTrafficLightData) => void;
}>) {
  const [trafficLightData, setTrafficLightData] = useState<TTrafficLightData | null>(null);

  useEffect(() => {
    if (!customerId) {
      return;
    }

    api
      .getV1personCustomerIdverificationpipeline({ params: { customerId } })
      .then((data) => {
        const trafficLightData = extractTrafficLightData(data);
        setTrafficLightData(trafficLightData);
        onDataChange?.(trafficLightData);
      })
      .catch((err) => {
        console.warn('Failed to get customer verification pipeline:', err);
      });
  }, []);

  return <TrafficLightComponent data={trafficLightData} operations={operations} />;
}
