import { Link, Stack, Typography } from '@mui/material';
import {
  Alert,
  CircularProgressButton,
  ColoredIcon,
  DisplayAlertErrors,
  EmailInput,
} from '@verifime/components';
import tokens from '@verifime/design-tokens';
import { SERVER_ERRORS } from '@verifime/utils';
import { Dispatch, FormEvent, ReactNode, SetStateAction, useEffect, useState } from 'react';

type TSignUpApiResultAction = 'MAGIC_LINK' | 'LOGIN' | 'ERROR';
export type TSignUpApiRequestAction = (email: string) => Promise<TSignUpApiResultAction>;

export type TSignUpProps = {
  signUpApiRequestAction: TSignUpApiRequestAction;
  initalEmail?: string;
  renderExtraEnterEmailChildren?: ({
    setError,
  }: {
    setError: Dispatch<SetStateAction<string>>;
  }) => ReactNode;
};

export default function SignUp({
  signUpApiRequestAction,
  initalEmail,
  renderExtraEnterEmailChildren,
}: Readonly<TSignUpProps>) {
  const [email, setEmail] = useState('');
  const [display, setDisplay] = useState<'ENTER_EMAIL' | 'SENT_EMAIL'>('ENTER_EMAIL');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!initalEmail) {
      return;
    }
    setEmail(initalEmail);
  }, [initalEmail]);

  const handleSignUp = () => {
    signUpApiRequestAction(email)
      .then((action) => {
        if (action === 'MAGIC_LINK') {
          setDisplay('SENT_EMAIL');
        }

        if (action === 'ERROR') {
          setError(SERVER_ERRORS.default);
        }
      })
      .catch((err) => setError(err.message))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Stack
      width={tokens.sizeXs}
      padding={`${tokens.spacingXl} ${tokens.spacingBase}`}
      gap={tokens.spacingXl}
      alignItems="center"
      component="form"
      onSubmit={(event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);
        setError(null);
        handleSignUp();
      }}
    >
      {display === 'ENTER_EMAIL' && (
        <>
          <Typography variant="h5">Welcome to VerifiMe</Typography>
          <EmailInput initialEmail={email} onEmailChange={setEmail} isDisabled={isSubmitting} />
          {renderExtraEnterEmailChildren?.({ setError })}
          <CircularProgressButton
            fullWidth
            size="large"
            color="primary"
            type="submit"
            variant="contained"
            sx={{
              gap: tokens.spacingXs,
            }}
            disabled={!email}
            isShowProgress={isSubmitting}
          >
            Continue
          </CircularProgressButton>

          <Typography variant="body1">
            Already on VerifiMe? <Link href="/">Sign in</Link>
          </Typography>
        </>
      )}
      {display === 'SENT_EMAIL' && (
        <>
          <Typography variant="h5">Check your email</Typography>
          <Alert severity="info" icon={<ColoredIcon iconName="emailRounded" />}>
            <Typography>An invitation email has been sent to</Typography>
            <Typography variant="h6">{email}</Typography>
            <Typography>Please check your inbox and follow the instructions.</Typography>
            <Typography>
              If it&#39;s not there, check your junk/spam folder. You can close this tab now.
            </Typography>
          </Alert>
        </>
      )}
      {error && <DisplayAlertErrors errors={[{ message: error }]} />}
    </Stack>
  );
}
