import ColoredIcon from '../ColoredIconAndChip/ColoredIcon';
import { STATUS_COLOR_AND_ICON_MAPPING } from './constant';
import { TStatus } from './types';

export default function StatusIcon({
  status,
  iconSize = 'large',
}: Readonly<{
  status: TStatus;
  iconSize?: 'small' | 'inherit' | 'large' | 'medium';
}>) {
  const { colorName, iconName } = STATUS_COLOR_AND_ICON_MAPPING[status] || {};
  return <ColoredIcon colorName={colorName} iconName={iconName} iconSize={iconSize}></ColoredIcon>;
}
