import { TColorName, TIconName } from '../ColoredIconAndChip';
import { TStatus } from './types';

export const STATUS_COLOR_AND_ICON_MAPPING: {
  [status in TStatus]: { colorName: TColorName; iconName: TIconName };
} = {
  Low: {
    colorName: 'verifiedIdentity',
    iconName: 'checkCircleRounded',
  },
  Medium: {
    colorName: 'warningDark',
    iconName: 'warningRounded',
  },
  High: {
    colorName: 'rejectedIdentity',
    iconName: 'cancelRounded',
  },
  Extreme: {
    colorName: 'extreme',
    iconName: 'blockRounded',
  },
  Pending: {
    colorName: 'verificationPending',
    iconName: 'remove',
  },
  InProgress: { colorName: 'unverifiedIdentity', iconName: 'pending' },
  Pass: { colorName: 'verifiedIdentity', iconName: 'checkCircleRounded' },
  Fail: { colorName: 'rejectedIdentity', iconName: 'cancelRounded' },
  Error: { colorName: 'rejectedIdentity', iconName: 'errorRounded' },
  Incomplete: { colorName: 'extreme', iconName: 'pending' },
  Expired: { colorName: 'rejectedIdentity', iconName: 'cancelRounded' },
};
