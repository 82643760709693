import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Stack, SvgIcon, Theme, Tooltip, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { ReactNode } from 'react';
import StatusBox from '../Status/StatusBox';
import { TStatus } from '../Status/types';

import DocCaptureIcon from './doc_capture.svg';
import DocDataMatchIcon from './doc_data_match.svg';
import LivenessIcon from './liveness.svg';
import PhotoPatchIcon from './photo_match.svg';

export enum IdentityDocumentType {
  Passport = 'Passport',
  DriverLicence = 'DriverLicence',
  MedicareCard = 'MedicareCard',
  OtherIdentityDocument = 'OtherIdentityDocument',
}

type TTrafficLightDocument = 'Capture' | 'DataMatch';
type TTrafficLightFacecheck = 'Liveness' | 'PhotoMatch';

export type TTrafficLightStatus = Extract<TStatus, 'Pass' | 'Fail' | 'Incomplete'>;

export type TTrafficLightDataItem = {
  name: string;
  status: TTrafficLightStatus;
  lastUpdatedOn: string;
  details?: {
    [identityType in IdentityDocumentType]?: Omit<TTrafficLightDataItem, 'name'>;
  };
};

export type TTrafficLightDocumentData = {
  [key in TTrafficLightDocument]: TTrafficLightDataItem;
};

export type TTrafficLightFacecheckData = {
  [key in TTrafficLightFacecheck]: TTrafficLightDataItem;
};

export type TTrafficLightData = TTrafficLightDocumentData & TTrafficLightFacecheckData;

type TTrafficLightKey = keyof TTrafficLightData;

export type TTrafficLightOperations = {
  [key in TTrafficLightKey]?: { onClick: (dataItem: TTrafficLightDataItem) => Promise<void> };
};

const HelpTooltip = ({ title }: { title: ReactNode }) => {
  return (
    <Tooltip title={<Box padding={tokens.spacing2xs}>{title}</Box>} placement="right">
      <HelpOutlineIcon />
    </Tooltip>
  );
};
const TRAFFIC_LIGHT_HELP_DESCRIPTIONS: { [k in TTrafficLightKey]: ReactNode } = {
  Capture: (
    <HelpTooltip
      title={
        <Stack gap={tokens.spacingXs}>
          <Typography>Passing this step means:</Typography>
          <Typography
            component="ol"
            sx={{
              paddingLeft: 2,
              gap: tokens.spacingXs,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography component="li">
              At least two approved identity documents are provided.
            </Typography>
            <Typography component="li">
              The data contained on these identity documents have been read and captured.
            </Typography>
            <Typography component="li">
              These documents have been electronically analysed to check for authenticity.
            </Typography>
          </Typography>
        </Stack>
      }
    />
  ),
  DataMatch: (
    <HelpTooltip
      title={
        <Typography>
          Passing this step means that the information (data) contained on the identity document has
          been successfully validated (matched) against an external data source - such as the
          government data service
        </Typography>
      }
    />
  ),
  Liveness: (
    <HelpTooltip
      title={
        <Stack gap={tokens.spacingXs}>
          <Typography>Passing this step means:</Typography>
          <Typography
            component="ol"
            sx={{
              paddingLeft: 2,
              gap: tokens.spacingXs,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography component="li">
              A video capture of the customer has been completed.
            </Typography>
            <Typography component="li">
              This video has been electronically analysed to check for liveliness of the person.
            </Typography>
          </Typography>
        </Stack>
      }
    />
  ),
  PhotoMatch: (
    <HelpTooltip
      title={
        <Stack gap={tokens.spacingBase}>
          <Typography>
            This step compares the person captured within the liveliness step against the photo on
            the identity document provided and calculates a probability percentage (%) of this being
            the same person.
          </Typography>
          <Typography>The match percentage is shown when this step is completed. </Typography>
        </Stack>
      }
    />
  ),
};

const TRAFFIC_LIGHT_FOOTER_ICONS: { [k in TTrafficLightKey]: ReactNode } = {
  Capture: (
    <SvgIcon
      component={DocCaptureIcon}
      inheritViewBox
      sx={{
        fill: (theme: Theme) => theme.palette.primary.main,
        fillOpacity: 0.5,
        width: 'auto', // Allow natural width
        height: 'auto', // Allow natural height
        fontSize: 'inherit', // Remove SvgIcon's default font-size
      }}
    />
  ),
  DataMatch: (
    <SvgIcon
      component={DocDataMatchIcon}
      inheritViewBox
      sx={{
        fill: (theme: Theme) => theme.palette.primary.main,
        fillOpacity: 0.5,
        width: 'auto', // Allow natural width
        height: 'auto', // Allow natural height
        fontSize: 'inherit', // Remove SvgIcon's default font-size
      }}
    />
  ),
  Liveness: (
    <SvgIcon
      component={LivenessIcon}
      inheritViewBox
      sx={{
        fill: (theme: Theme) => theme.palette.primary.main,
        fillOpacity: 0.5,
        width: 'auto', // Allow natural width
        height: 'auto', // Allow natural height
        fontSize: 'inherit', // Remove SvgIcon's default font-size
      }}
    />
  ),
  PhotoMatch: (
    <SvgIcon
      component={PhotoPatchIcon}
      inheritViewBox
      sx={{
        fill: (theme: Theme) => theme.palette.primary.main,
        fillOpacity: 0.5,
        width: 'auto', // Allow natural width
        height: 'auto', // Allow natural height
        fontSize: 'inherit', // Remove SvgIcon's default font-size
      }}
    />
  ),
};

export default function TrafficLight({
  data,
  operations,
}: Readonly<{ data: TTrafficLightData; operations?: TTrafficLightOperations }>) {
  if (!data || Object.keys(data).length < 1) {
    return;
  }

  return (
    <Stack direction="row" gap={tokens.spacingXl} flexWrap="wrap">
      {Object.entries(data).map(([key, item]) => {
        const typedKey = key as TTrafficLightKey;
        return (
          <Box
            key={key + item.name}
            sx={{
              flex: 1,
              cursor: operations?.[typedKey]?.onClick && 'pointer',
              height: tokens.size2xs,
            }}
            onClick={() => operations?.[typedKey]?.onClick(item)}
          >
            <StatusBox
              title={item.name}
              status={item.status as TStatus}
              date={item.lastUpdatedOn}
              dateTitle="Last updated on"
              headerSlot={TRAFFIC_LIGHT_HELP_DESCRIPTIONS[typedKey]}
              footSlot={TRAFFIC_LIGHT_FOOTER_ICONS[typedKey]}
              sx={{ width: '100%', height: '100%' }}
            ></StatusBox>
          </Box>
        );
      })}
    </Stack>
  );
}
