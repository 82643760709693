import { SxProps } from '@mui/material';
import StatusBox from '../Status/StatusBox';
import { TRiskLevels } from './types';

export default function Risk({
  riskLevel,
  assessedDate,
  title = 'Current Risk',
  sx,
}: Readonly<{
  riskLevel: TRiskLevels;
  assessedDate: string;
  title?: string;
  sx?: SxProps;
}>) {
  const riskLevelLabel = riskLevel === 'Pending' ? 'Verification Pending' : riskLevel;
  return (
    <StatusBox
      status={riskLevel}
      title={title}
      date={assessedDate}
      dateTitle="Assessed on"
      label={riskLevelLabel}
      sx={sx}
    />
  );
}
