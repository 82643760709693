'use client';

import { useEffect, useState } from 'react';

import type { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';

import tokens from '@verifime/design-tokens';
import { FileType, SERVER_ERRORS, stringUtils, useMobile } from '@verifime/utils';
import FileDragAndDropSelector from '../FileDragAndDropSelector';
import FileSelector from '../FileSelector';
import { DisplayAlertErrors } from '../Form';
import FileList from './FileList';
import { ListFileItem } from './ListFileItem';

type TextFieldProps = Omit<
  MuiTextFieldProps,
  'onChange' | 'select' | 'type' | 'multiline' | 'defaultValue'
>;

export enum UploadStatus {
  TO_BE_UPLOADED = 'ToBeUploaded',
  UPLOADING = 'Uploading',
  COMPLETE = 'Complete',
  FAILED = 'Failed',
}

export type TFileUploadStatus = {
  fileName: string;
  fileId: string;
  fileSizeInBytes: number;
  fileUploadUrl?: string;
  uploadStatus: UploadStatus;
  uploadInfo: string;
  file?: File;
  fileType?: string;
  error?: string;
};

export type TFilesUploadStatus = {
  [fileId: string]: TFileUploadStatus;
};

export type TFileUploadUrl = {
  fileName: string;
  fileId: string;
  uploadUrl: string;
};

export type TFileUploadResult = {
  fileId: string;
  fileUploadUrl: string;
  isSuccess: boolean;
  message?: string;
  // Controls whether to show the file upload result or remove from the result list
  isRemoveFileUploadResult?: boolean;
};

export type TExistingUploadedFile = {
  fileName: string;
  fileId: string;
  fileSizeInBytes: number;
  error?: string;
};

export type TFileToDelete = {
  fileName: string;
  fileId: string;
  error?: string;
};

const fileUploadInfo = (text: string, status: UploadStatus) => `${text} • ${status}`;

export const fileSizeDisplay = (fileSizeInBytes: number) =>
  `${(fileSizeInBytes / (1024 * 1024)).toFixed(2)}MB`;

const UNEXPECTED_UPLOAD_ERROR = `Unexpected upload error happened`;

export type MuiFileInputProps = TextFieldProps & {
  isReadonly?: boolean;
  fileTypes?: FileType[];
  // Max file size in MB
  maxFileSizeInMB?: number;
  existingUploadedFiles?: TExistingUploadedFile[];
  isDetectSameFileSelection?: boolean;
  isMultiple?: boolean;
  isDraggable?: boolean;
  onChange?: (filesUploadStatus: TFileUploadStatus[]) => void;
  onRetrieveFileUploadUrls: (files: File[]) => Promise<TFileUploadUrl[] | undefined>;
  onUploadFile: (fileUploadStatus: TFileUploadStatus) => Promise<TFileUploadResult>;
  onDeleteFile?: (
    fileToDelete: TFileToDelete,
    onDeleteNewUploadFile?: (success: boolean) => void,
  ) => void;
  // Called when a single file upload is done, either succeeded or failed.
  onSingleUploadDone?: (fileUploadStatus: TFileUploadStatus) => void;
  // Called when all uploads are successfully
  onAllUploadsSuccessful?: () => void;
  onAllUploadsDone?: (filesUploadStatus: TFilesUploadStatus) => void;
};

export default function FileUploader<T>({
  label,
  fileTypes = [],
  maxFileSizeInMB = 100,
  existingUploadedFiles,
  isDetectSameFileSelection,
  isMultiple,
  isReadonly,
  isDraggable,
  onChange,
  onRetrieveFileUploadUrls,
  onUploadFile,
  onDeleteFile,
  onSingleUploadDone,
  onAllUploadsSuccessful,
  onAllUploadsDone,
}: MuiFileInputProps) {
  const [filesUploadStatus, setFilesUploadStatus] = useState<TFilesUploadStatus>();
  const [error, setError] = useState<string>();
  const [disableDragAndDropFile, setDisableDragAndDropFile] = useState(false);

  const isMobile = useMobile();

  useEffect(() => {
    if (filesUploadStatus) {
      const filesToUpload = Object.values(filesUploadStatus).filter(
        (fileUploadStatus) => fileUploadStatus.uploadStatus === UploadStatus.TO_BE_UPLOADED,
      );

      const filesUploadPromises: Promise<TFilesUploadStatus>[] = filesToUpload.map(
        (fileUploadStatus) => {
          return new Promise(async (resolve, reject) => {
            setFilesUploadStatus((prevFilesUploadStatus) => ({
              ...prevFilesUploadStatus,
              ...{
                [fileUploadStatus.fileId]: {
                  ...fileUploadStatus,
                  uploadStatus: UploadStatus.UPLOADING,
                  uploadInfo: fileUploadInfo(
                    fileSizeDisplay(fileUploadStatus.fileSizeInBytes),
                    UploadStatus.UPLOADING,
                  ),
                },
              },
            }));

            try {
              const fileUploadResult = await onUploadFile(fileUploadStatus);

              if (fileUploadResult.isSuccess) {
                const updatedFileUploadStatus = {
                  [fileUploadResult.fileId]: {
                    ...fileUploadStatus,
                    uploadStatus: UploadStatus.COMPLETE,
                    uploadInfo: fileUploadInfo(
                      fileSizeDisplay(fileUploadStatus.fileSizeInBytes),
                      UploadStatus.COMPLETE,
                    ),
                  },
                };

                if (fileUploadResult.isRemoveFileUploadResult) {
                  setFilesUploadStatus((prevFilesUploadStatus) => {
                    const newFilesUploadStatus = { ...prevFilesUploadStatus };
                    delete newFilesUploadStatus[fileUploadResult.fileId];
                    return newFilesUploadStatus;
                  });
                } else {
                  setFilesUploadStatus((prevFilesUploadStatus) => ({
                    ...prevFilesUploadStatus,
                    ...updatedFileUploadStatus,
                  }));
                }

                if (typeof onSingleUploadDone === 'function') {
                  onSingleUploadDone(updatedFileUploadStatus[fileUploadResult.fileId]);
                }

                resolve(updatedFileUploadStatus);
              } else {
                const updatedFileUploadStatus = {
                  [fileUploadResult.fileId]: {
                    ...fileUploadStatus,
                    uploadStatus: UploadStatus.FAILED,
                    uploadInfo: fileUploadInfo(
                      fileUploadResult.message || UNEXPECTED_UPLOAD_ERROR,
                      UploadStatus.FAILED,
                    ),
                  },
                };

                setFilesUploadStatus((prevFilesUploadStatus) => ({
                  ...prevFilesUploadStatus,
                  ...updatedFileUploadStatus,
                }));

                if (typeof onSingleUploadDone === 'function') {
                  onSingleUploadDone(updatedFileUploadStatus[fileUploadResult.fileId]);
                }

                reject(updatedFileUploadStatus);
              }
            } catch (err) {
              const updatedFileUploadStatus = {
                [fileUploadStatus.fileId]: {
                  ...fileUploadStatus,
                  uploadStatus: UploadStatus.FAILED,
                  uploadInfo: fileUploadInfo(UNEXPECTED_UPLOAD_ERROR, UploadStatus.FAILED),
                },
              };

              setFilesUploadStatus((prevFilesUploadStatus) => ({
                ...prevFilesUploadStatus,
                ...updatedFileUploadStatus,
              }));

              if (typeof onSingleUploadDone === 'function') {
                onSingleUploadDone(updatedFileUploadStatus[fileUploadStatus.fileId]);
              }

              reject(updatedFileUploadStatus);
            }
          });
        },
      );

      if (filesUploadPromises.length > 0) {
        Promise.allSettled(filesUploadPromises).then((results) => {
          if (typeof onAllUploadsDone === 'function') {
            onAllUploadsDone(
              results.reduce((aggr, result) => {
                return result.status === 'fulfilled'
                  ? { ...aggr, ...result.value }
                  : { ...aggr, ...result.reason };
              }, {}),
            );
          }
        });
      }
    }

    // It means all uploades are successful When filesUploadStatus is an empty object
    if (filesUploadStatus && Object.keys(filesUploadStatus).length === 0) {
      if (typeof onAllUploadsSuccessful === 'function') {
        onAllUploadsSuccessful();
      }
    }
  }, [filesUploadStatus]);

  const handleChange = async (selectedFiles: File[]) => {
    if (selectedFiles.length === 0) {
      return;
    }

    let fileUploadUrls: TFileUploadUrl[] = [];

    try {
      fileUploadUrls = (await onRetrieveFileUploadUrls(selectedFiles)) || [];

      const selectedFilesUploadStatus: TFilesUploadStatus =
        selectedFiles.reduce<TFilesUploadStatus>((filesUploadStatus, file) => {
          const fileSizeInMB: number = file.size / (1024 * 1024);
          let uploadStatus = UploadStatus.TO_BE_UPLOADED;
          let uploadInfo = '';

          if (fileSizeInMB > maxFileSizeInMB) {
            uploadStatus = UploadStatus.FAILED;
            uploadInfo = fileUploadInfo('File too large', UploadStatus.FAILED);
          }

          let fileUploadUrl = fileUploadUrls.find(
            (fileUploadUrl) => fileUploadUrl.fileName === file.name,
          );

          // To reuse this file uploader to upload files directly and no need to call preSign to get upload urls
          if (!fileUploadUrl) {
            fileUploadUrl = {
              // Random string to make sure multiple uploads
              fileId: stringUtils.getRandomString('string'),
              fileName: file.name,
              uploadUrl: '',
            };
          }

          return {
            ...filesUploadStatus,
            [fileUploadUrl.fileId]: {
              fileName: file.name,
              fileType: file.type,
              fileId: fileUploadUrl.fileId,
              fileSizeInBytes: file.size,
              fileUploadUrl: fileUploadUrl.uploadUrl,
              uploadStatus,
              uploadInfo,
              file,
            },
          };
        }, {});

      const updatedFilesUploadStatus = { ...filesUploadStatus, ...selectedFilesUploadStatus };

      setFilesUploadStatus(updatedFilesUploadStatus);

      if (typeof onChange === 'function') {
        onChange(Object.values(updatedFilesUploadStatus));
      }
    } catch (err) {
      setError('Failed to get upload urls');
    }
  };

  const handleDeleteFile = (fileUploadStatusItem: TFileUploadStatus) => {
    const onDeleteNewUploadFile = (success: boolean) => {
      const newFilesUpladStatus = { ...filesUploadStatus };
      if (success) {
        delete newFilesUpladStatus[fileUploadStatusItem.fileId];
      } else {
        newFilesUpladStatus[fileUploadStatusItem.fileId].error =
          'Failed to delete file, please contact support@verifime.com for help.';
      }
      setFilesUploadStatus(newFilesUpladStatus);
    };
    if (fileUploadStatusItem.uploadStatus === UploadStatus.FAILED) {
      onDeleteNewUploadFile(true);
    } else {
      if (typeof onDeleteFile === 'function') {
        onDeleteFile(fileUploadStatusItem, onDeleteNewUploadFile);
      }
    }
  };

  useEffect(() => {
    // If upload a single file, the drop zone will be disabled if uploading failed or one file already uploaded
    if (!isMultiple) {
      if (
        (filesUploadStatus && Object.keys(filesUploadStatus).length > 0) ||
        (existingUploadedFiles?.length || 0) > 0
      ) {
        setDisableDragAndDropFile(true);
      } else {
        setDisableDragAndDropFile(false);
      }
    } else {
      setDisableDragAndDropFile(false);
    }
  }, [isMultiple, existingUploadedFiles, filesUploadStatus]);

  return (
    <>
      {isDraggable && !isMobile ? (
        <FileDragAndDropSelector
          label={label}
          fileTypes={fileTypes}
          maxFileSizeInMB={maxFileSizeInMB}
          isMultiple={isMultiple}
          isDisabled={isReadonly || disableDragAndDropFile}
          onChange={handleChange}
          onClick={() => setError(null)}
        />
      ) : (
        <FileSelector
          label={label}
          fileTypes={fileTypes}
          maxFileSizeInMB={maxFileSizeInMB}
          isMultiple
          isDetectSameFileSelection={isDetectSameFileSelection}
          isDisabled={isReadonly || (!isMultiple && existingUploadedFiles?.length > 0)}
          onChange={handleChange}
          onClick={() => setError(null)}
        />
      )}
      {filesUploadStatus && Object.keys(filesUploadStatus).length > 0 && (
        <FileList>
          {Object.values(filesUploadStatus).map(
            (fileUploadStatusItem: TFileUploadStatus, index) => {
              return (
                <ListFileItem
                  key={index}
                  fileUploadStatus={fileUploadStatusItem}
                  onDeleteFile={() => handleDeleteFile(fileUploadStatusItem)}
                  isReadonly={isReadonly}
                />
              );
            },
          )}
        </FileList>
      )}
      {existingUploadedFiles && existingUploadedFiles.length > 0 && (
        <FileList>
          {existingUploadedFiles.map(({ fileName, fileId, fileSizeInBytes, error }) => {
            return (
              <ListFileItem
                key={fileId}
                fileUploadStatus={{
                  fileName,
                  fileId,
                  fileSizeInBytes,
                  uploadStatus: UploadStatus.COMPLETE,
                  uploadInfo: fileUploadInfo(
                    fileSizeDisplay(fileSizeInBytes),
                    UploadStatus.COMPLETE,
                  ),
                  error,
                }}
                onDeleteFile={onDeleteFile}
                isReadonly={isReadonly}
              />
            );
          })}
        </FileList>
      )}
      {error && (
        <DisplayAlertErrors
          errors={[{ message: SERVER_ERRORS.default }]}
          sx={{ mt: tokens.spacingBase }}
        />
      )}
    </>
  );
}
