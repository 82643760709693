import { Box, FormControlLabel, InputAdornment, MenuItem, Radio, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { TOption, stringUtils } from '@verifime/utils';
import React, { ReactNode } from 'react';
import { Control, FieldErrorsImpl, FieldValues, UseFormSetError } from 'react-hook-form';
import {
  FormDate,
  FormDateRange,
  FormFileInput,
  FormSelect,
  FormTextEditor,
  FormTextInput,
  TFormDateRangeRequired,
} from '.';
import FlexBox from '../FlexBox';
import ResponsiveBox from '../ResponsiveBox';
import FormCheckbox from './FormCheckbox';
import FormComboBox from './FormComboBox';
import FormNumberInput from './FormNumberInput';
import FormRadioGroup from './FormRadioGroup';
import { RenderType, TFormFieldsAndRules_New, TFromFieldInfo_New, TRenderType } from './utils';

export type TFieldsOperations<TFieldValues extends FieldValues = FieldValues> = Record<
  keyof TFieldValues,
  {
    initValue?: any;
    onOptionChange?: (value: any) => void;
    onFileChange?: (file: File) => void;
    disabled?: () => boolean;
    onTextChange?: (text: string) => void;
    onDateChange?: (date: Date) => void;
    onDateRangeChange?: (dateFieldType: 'start' | 'end', date: Date) => void;
    onCheckBoxChange?: (isChecked: boolean) => void;
    onRadioChange?: (value: string) => void;
    onNumberChange?: (value: number) => void;
  }
>;

const fieldComponentMapping: { [renderType in TRenderType]: (...props: any) => JSX.Element } = {
  [RenderType.Text]: ({
    fieldInfo,
    control,
    errors,
    onTextChange,
  }: {
    fieldInfo: TFromFieldInfo_New;
    control: Control<FieldValues, any>;
    errors: Partial<
      FieldErrorsImpl<{
        [x: string]: any;
      }>
    >;
    // TODO: remove this, it replaced by fieldInfo.onValueChange
    onTextChange?: (text: string) => void;
  }) => (
    <FormTextInput
      required={isShowRequiredAsterisk(fieldInfo)} // To control whether show * or not in screen
      name={fieldInfo.fieldName}
      label={fieldInfo.label}
      type="text"
      margin={fieldInfo.margin}
      fullWidth
      size={fieldInfo.size}
      control={control}
      error={errors[fieldInfo.fieldName] as any}
      isValueUpperCase={fieldInfo.isValueUpperCase}
      onTextChange={(text) => {
        onTextChange?.(text);
        fieldInfo.onValueChange?.(text);
      }}
      sx={fieldInfo.sx}
      InputProps={{
        startAdornment: fieldInfo.startAdornment ? (
          <InputAdornment position="start">{fieldInfo.startAdornment}</InputAdornment>
        ) : (
          ''
        ),
      }}
      disabled={fieldInfo.disabled}
      multiline={fieldInfo.multiline}
      minRows={fieldInfo.multiline ? 2 : null}
    />
  ),
  [RenderType.Number]: ({
    fieldInfo,
    control,
    errors,
    onNumberChange,
  }: {
    fieldInfo: TFromFieldInfo_New;
    control: Control<FieldValues, any>;
    errors: Partial<
      FieldErrorsImpl<{
        [x: string]: any;
      }>
    >;
    // TODO: remove this, it replaced by fieldInfo.onValueChange
    onNumberChange?: (text: string) => void;
  }) => (
    <FormNumberInput
      required={isShowRequiredAsterisk(fieldInfo)} // To control whether show * or not in screen
      name={fieldInfo.fieldName}
      label={fieldInfo.label}
      margin={fieldInfo.margin}
      fullWidth
      size={fieldInfo.size}
      control={control}
      error={errors[fieldInfo.fieldName] as any}
      onNumberChange={(text) => {
        onNumberChange?.(text);
        fieldInfo.onValueChange?.(text);
      }}
      sx={fieldInfo.sx}
      InputProps={{
        startAdornment: fieldInfo.startAdornment ? (
          <InputAdornment position="start">{fieldInfo.startAdornment}</InputAdornment>
        ) : (
          ''
        ),
      }}
      disabled={fieldInfo.disabled}
      multiline={fieldInfo.multiline}
      minRows={fieldInfo.multiline ? 2 : null}
    />
  ),
  [RenderType.Select]: ({
    fieldInfo,
    control,
    formFieldsWithDefaultValues,
    initValue,
    onOptionChange,
    disabled,
  }: {
    fieldInfo: TFromFieldInfo_New;
    control: Control<FieldValues, any>;
    errors: Partial<
      FieldErrorsImpl<{
        [x: string]: any;
      }>
    >;
    formFieldsWithDefaultValues?: Record<string, any>;
    initValue: any;
    // TODO: remove this, it replaced by fieldInfo.onValueChange
    onOptionChange?: (value: any) => void;
    disabled?: () => boolean;
  }) => (
    <FormSelect
      required={isShowRequiredAsterisk(fieldInfo)} // To control whether show * or not in screen
      name={fieldInfo.fieldName}
      label={fieldInfo.label}
      control={control}
      defaultValue={formFieldsWithDefaultValues?.[fieldInfo.fieldName] || initValue || ''}
      size={fieldInfo.size}
      margin={fieldInfo.margin}
      onOptionChange={(event) => {
        onOptionChange?.(event.target.value);
        fieldInfo.onValueChange?.(event.target.value);
      }}
      disabled={disabled?.() || fieldInfo.disabled}
      sx={fieldInfo.sx}
    >
      {typeof fieldInfo.renderItems === 'function'
        ? fieldInfo.renderItems()
        : fieldInfo.items?.map((option: TOption) => (
            <MenuItem key={option.code} value={option.code}>
              {option.label}
            </MenuItem>
          ))}
    </FormSelect>
  ),
  [RenderType.Date]: ({
    fieldInfo,
    control,
    formFieldsWithDefaultValues,
    initValue,
    onDateChange,
  }: {
    fieldInfo: TFromFieldInfo_New;
    control: Control<FieldValues, any>;
    errors: Partial<
      FieldErrorsImpl<{
        [x: string]: any;
      }>
    >;
    formFieldsWithDefaultValues?: Record<string, any>;
    initValue: any;
    // TODO: remove this, it replaced by fieldInfo.onValueChange
    onDateChange?: (date: Date) => void;
  }) => (
    <FormDate
      required={isShowRequiredAsterisk(fieldInfo)} // To control whether show * or not in screen
      name={fieldInfo.fieldName}
      label={fieldInfo.label}
      control={control}
      size={fieldInfo.size}
      margin={fieldInfo.margin}
      maxDate={fieldInfo.maxDate}
      minDate={fieldInfo.minDate}
      defaultValue={formFieldsWithDefaultValues?.[fieldInfo.fieldName] || initValue || ''}
      onDateChange={(date) => {
        onDateChange?.(date);
        fieldInfo.onValueChange?.(date);
      }}
      sx={fieldInfo.sx}
      disabled={fieldInfo.disabled}
    />
  ),
  [RenderType.DateRange]: ({
    required,
    fieldInfo,
    control,
    formFieldsWithDefaultValues,
    onDateRangeChange,
  }: {
    required: TFormDateRangeRequired;
    fieldInfo: TFromFieldInfo_New;
    control: Control<FieldValues, any>;
    formFieldsWithDefaultValues?: Record<string, any>;
    // TODO: remove this, it replaced by fieldInfo.onValueChange
    onDateRangeChange?: (dateFieldType: 'start' | 'end', date: Date) => void;
  }) => {
    if (
      !('start' in (fieldInfo.dateRangeFieldsLabels || {})) &&
      !('end' in (fieldInfo.dateRangeFieldsLabels || {}))
    ) {
      throw new Error(`FormDateRange needs label: { start: string; end: string }`);
    }

    const labels = fieldInfo.dateRangeFieldsLabels;

    const defaultValues: { [label: string]: string } = {};
    for (const [label, labelValue] of Object.entries(labels)) {
      defaultValues[label] =
        formFieldsWithDefaultValues?.[
          stringUtils.generateCamelCaseString(fieldInfo.fieldName, labelValue)
        ];
    }

    return (
      <Box sx={fieldInfo.sx}>
        {fieldInfo.label && <Typography variant="subtitle2">{fieldInfo.label}</Typography>}
        <FormDateRange
          name={fieldInfo.fieldName}
          labels={labels}
          required={required}
          control={control}
          size={fieldInfo.size}
          margin={fieldInfo.margin}
          maxDate={fieldInfo.maxDate}
          minDate={fieldInfo.minDate}
          defaultValues={defaultValues}
          onDateRangeChange={(dateFieldType, date) => {
            onDateRangeChange?.(dateFieldType, date);
            fieldInfo.onValueChange?.({ dateFieldType, date });
          }}
          disabled={fieldInfo.disabled}
        />
      </Box>
    );
  },
  [RenderType.Checkbox]: ({
    fieldInfo,
    control,
    errors,
    formFieldsWithDefaultValues,
    initValue,
    onCheckBoxChange,
  }: {
    fieldInfo: TFromFieldInfo_New;
    control: Control<FieldValues, any>;
    errors: Partial<
      FieldErrorsImpl<{
        [x: string]: any;
      }>
    >;
    formFieldsWithDefaultValues?: Record<string, any>;
    initValue: boolean;
    // TODO: remove this, it replaced by fieldInfo.onValueChange
    onCheckBoxChange?: (isChecked: boolean) => void;
  }) => (
    <FormCheckbox
      required={isShowRequiredAsterisk(fieldInfo)}
      name={fieldInfo.fieldName}
      label={fieldInfo.complexLabel ?? fieldInfo.label}
      control={control}
      size={fieldInfo.size}
      defaultChecked={formFieldsWithDefaultValues?.[fieldInfo.fieldName] || initValue || false}
      error={errors[fieldInfo.fieldName] as any}
      onCheckBoxChange={(isChecked) => {
        onCheckBoxChange?.(isChecked);
        fieldInfo.onValueChange?.(isChecked);
      }}
      sx={fieldInfo.sx}
      disabled={fieldInfo.disabled}
    />
  ),
  [RenderType.File]: ({
    fieldInfo,
    control,
    onFileChange,
  }: {
    fieldInfo: TFromFieldInfo_New;
    control: Control<FieldValues, any>;
    errors: Partial<
      FieldErrorsImpl<{
        [x: string]: any;
      }>
    >;
    // TODO: remove this, it replaced by fieldInfo.onValueChange
    onFileChange: (file: File) => void;
  }) => (
    <Box sx={{ width: '100%', my: tokens.spacingBase, ...(fieldInfo.sx || {}) }}>
      <FormFileInput
        name={fieldInfo.fieldName}
        label={fieldInfo.label}
        control={control}
        helpText={fieldInfo?.helpText}
        onChange={(file) => {
          onFileChange?.(file);
          fieldInfo.onValueChange?.(file);
        }}
        required={isShowRequiredAsterisk(fieldInfo)} // To control whether show * or not in screen
        disabled={fieldInfo.disabled}
      />
    </Box>
  ),
  [RenderType.TextEditor]: ({
    fieldInfo,
    control,
    errors,
  }: {
    fieldInfo: TFromFieldInfo_New;
    control: Control<FieldValues>;
    errors: Partial<
      FieldErrorsImpl<{
        [x: string]: any;
      }>
    >;
  }) => (
    <FormTextEditor
      required={isShowRequiredAsterisk(fieldInfo)}
      fieldInfo={fieldInfo}
      control={control}
      errors={errors}
      disabled={fieldInfo.disabled}
    />
  ),
  [RenderType.RadioGroup]: ({
    fieldInfo,
    control,
    formFieldsWithDefaultValues,
    initValue,
    onRadioChange,
  }: {
    fieldInfo: TFromFieldInfo_New;
    control: Control<FieldValues, any>;
    errors: Partial<
      FieldErrorsImpl<{
        [x: string]: any;
      }>
    >;
    formFieldsWithDefaultValues?: Record<string, any>;
    initValue: any;
    // TODO: remove this, it replaced by fieldInfo.onValueChange
    onRadioChange?: (value: string) => void;
  }) => {
    if (typeof fieldInfo.renderItems !== 'function' && !fieldInfo.items) {
      throw new Error(`FormRadioGroup needs items property or a customized renderItems function`);
    }

    let defaultValue: string = null;
    if (typeof fieldInfo.renderItems !== 'function') {
      const hasDefaultItem = fieldInfo.items.find((item) => item.selectedByDefault);
      defaultValue = hasDefaultItem?.code;
    }

    return (
      <FormRadioGroup
        required={isShowRequiredAsterisk(fieldInfo)} // To control whether show * or not in screen
        name={fieldInfo.fieldName}
        label={fieldInfo.label}
        control={control}
        defaultValue={
          formFieldsWithDefaultValues?.[fieldInfo.fieldName] || initValue || defaultValue || ''
        }
        onRadioChange={(value) => {
          onRadioChange?.(value);
          fieldInfo.onValueChange?.(value);
        }}
      >
        {typeof fieldInfo.renderItems === 'function'
          ? fieldInfo.renderItems()
          : fieldInfo.items?.map((option: TOption) => (
              <FormControlLabel
                key={option.code}
                value={option.code}
                control={<Radio />}
                label={option.label}
                disabled={fieldInfo.disabled}
              />
            ))}
      </FormRadioGroup>
    );
  },
  [RenderType.ComboBox]: ({
    fieldInfo,
    control,
    setError,
    formFieldsWithDefaultValues,
    initValue,
  }: {
    fieldInfo: TFromFieldInfo_New;
    control: Control<FieldValues, any>;
    setError?: UseFormSetError<FieldValues>;
    formFieldsWithDefaultValues?: Record<string, any>;
    initValue?: string;
    errors: Partial<
      FieldErrorsImpl<{
        [x: string]: any;
      }>
    >;
    // TODO: remove this, it replaced by fieldInfo.onValueChange
    onTextChange?: (text: string) => void;
  }) => (
    <FormComboBox
      required={isShowRequiredAsterisk(fieldInfo)} // To control whether show * or not in screen
      name={fieldInfo.fieldName}
      label={fieldInfo.label}
      control={control}
      setError={setError}
      defaultValue={formFieldsWithDefaultValues?.[fieldInfo.fieldName] || initValue || ''}
      optionProvider={fieldInfo.optionProvider}
      disabled={fieldInfo.disabled}
    />
  ),
};

/**
 *
 * To make this change affections as less as possible,
 * do not change existing GenerateFormFields,
 * just reorganise form fields and rules in this component
 *
 * TODO:
 *  1. delete the old component `GenerateFormFields`
 *  2. rename this component to `GenerateFormFields`
 *  3. change individual & organisation form fields and rules files to match this new component
 */
export default function GenerateFormFields_New<TFieldValues extends FieldValues = FieldValues>({
  formFieldsAndRules,
  control,
  errors,
  setError,
  formFieldsWithDefaultValues,
  fieldsOperations,
  defaultMargin = 'normal',
  defaultSize = 'medium',
  ContainerComponent = React.useCallback(
    ({ children }) => <ResponsiveBox verticalAlign="middle">{children}</ResponsiveBox>,
    [ResponsiveBox],
  ),
  OuterContainerComponent = React.useCallback(
    ({ children }: { children: ReactNode }) => (
      <FlexBox direction="column" sx={{ gap: tokens.spacingXs }}>
        {children}
      </FlexBox>
    ),
    [FlexBox, tokens.spacingXs],
  ),
}: Readonly<{
  formFieldsAndRules: TFormFieldsAndRules_New<keyof TFieldValues>;
  control: Control<TFieldValues, any>;
  setError?: UseFormSetError<TFieldValues>;
  errors: Partial<FieldErrorsImpl<Record<keyof TFieldValues, any>>>;
  formFieldsWithDefaultValues?: Record<string, any>;
  /**
   *
   * TODO: remove this the functionality has covered by fieldInfo.onValueChange
   *
   * There are maybe several Select to be rendered in the `formFieldsAndRules`,
   * each of them may have different `initial value`, `onOptionChange` operations,
   * and those `onOptionChange` even `initial value` cannot be predefined in the `formFieldsAndRules`,
   * thus expose this to accept different operations of fields
   */
  fieldsOperations?: TFieldsOperations<TFieldValues>;
  ContainerComponent?: React.FunctionComponent<any>;
  OuterContainerComponent?: React.FunctionComponent<any>;
  defaultSize?: TFromFieldInfo_New['size'];
  defaultMargin?: TFromFieldInfo_New['margin'];
}>) {
  if (Object.keys(formFieldsAndRules || {}).length < 1) {
    return null;
  }

  return (
    <OuterContainerComponent>
      {Object.entries(formFieldsAndRules).map(([rowNumber, fieldsInfo]) => {
        const fieldsInfoWithDefaults = fieldsInfo.map((fieldInfo) => ({
          ...fieldInfo,
          size: fieldInfo.size ?? defaultSize,
          margin: fieldInfo.margin ?? defaultMargin,
        }));
        return (
          <ContainerComponent key={rowNumber}>
            {fieldsInfoWithDefaults.map((fieldInfo) => {
              const {
                initValue,
                onOptionChange,
                onFileChange,
                disabled,
                onTextChange,
                onDateChange,
                onDateRangeChange,
                onCheckBoxChange,
                onRadioChange,
                onNumberChange,
              } = fieldsOperations?.[fieldInfo.fieldName] || {};
              return (
                <React.Fragment key={String(fieldInfo.fieldName)}>
                  {fieldInfo.renderComponent
                    ? fieldInfo.renderComponent()
                    : fieldComponentMapping[fieldInfo.renderType]?.({
                        control,
                        setError,
                        errors,
                        fieldInfo,
                        formFieldsWithDefaultValues,
                        onOptionChange,
                        initValue,
                        onFileChange,
                        disabled,
                        onTextChange,
                        onDateChange,
                        onDateRangeChange,
                        onCheckBoxChange,
                        onRadioChange,
                        onNumberChange,
                      })}
                </React.Fragment>
              );
            })}
          </ContainerComponent>
        );
      })}
    </OuterContainerComponent>
  );
}

function isShowRequiredAsterisk(fieldInfo: TFromFieldInfo_New): boolean {
  // lazy means this field is not required by default,
  // thus we we do not want to show required asterisk
  if (fieldInfo.rules.describe().type === 'lazy') {
    return false;
  }

  return !fieldInfo.rules.describe().optional;
}
