import { ChipProps } from '@mui/material';
import { ColoredIcon } from '../ColoredIconAndChip';
import ColoredChip from '../ColoredIconAndChip/ColoredChip';
import { STATUS_COLOR_AND_ICON_MAPPING } from './constant';
import { TStatus } from './types';

export type TStatusChipsVariant = 'icon-only' | 'label-only' | 'both';
export type TStatusChipProps = {
  status: TStatus;
  label?: string;
  variant?: TStatusChipsVariant;
} & Omit<ChipProps, 'variant'>;

export default function StatusChip({
  status,
  label,
  variant = 'both',
  ...props
}: Readonly<TStatusChipProps>) {
  const colorAndIcon = STATUS_COLOR_AND_ICON_MAPPING[status];
  if (!colorAndIcon) {
    return <ColoredIcon iconName="remove" />;
  }

  if (variant === 'icon-only') {
    return <ColoredIcon {...colorAndIcon} colorName={colorAndIcon.colorName} isCompact={true} />;
  }

  return (
    <ColoredChip
      {...props}
      icon={variant === 'both' ? <ColoredIcon {...colorAndIcon} /> : null}
      colorName={colorAndIcon.colorName}
      label={label ?? status}
    />
  );
}
