import { CircularProgress, Paper } from '@mui/material';
import { api } from '@verifime/api-definition';
import { ClientLogo } from '@verifime/components';
import tokens from '@verifime/design-tokens';
import { useEffect, useState } from 'react';
import { TLogoResponse } from './types';

export default function ClientLogoByOrganisationCode({
  organisationCode,
  title,
}: Readonly<{ organisationCode: string; title?: string }>) {
  const [logoResponse, setLogoResponse] = useState<TLogoResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!organisationCode) {
      setLogoResponse(null);
      return;
    }
    setLoading(true);
    setIsError(false);
    api
      .getV1logoorganisationCodeOrganisationCode({
        params: { organisationCode },
      })
      .then(setLogoResponse)
      .catch(() => setIsError(true))
      .finally(() => setLoading(false));
  }, [organisationCode]);

  if (loading) {
    return (
      <Paper sx={{ width: '100%', padding: tokens.spacingBase, minHeight: tokens.size3xs }}>
        <CircularProgress />
      </Paper>
    );
  }

  if (!logoResponse || isError) {
    return;
  }

  return (
    <ClientLogo
      url={logoResponse.url}
      organisationName={logoResponse.organisationName}
      title={title}
    />
  );
}
