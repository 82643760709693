import { Box, Stack, SxProps, Typography, useTheme } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { formatToDDMMMYYYYHHMMAAA } from '@verifime/utils';
import { ReactNode } from 'react';
import { getColorByName } from '../ColoredIconAndChip';
import FlexPaper from '../FlexPaper';
import NameValueDisplay from '../NameValueDisplay';
import { STATUS_COLOR_AND_ICON_MAPPING } from './constant';
import StatusChip from './StatusChip';
import { TStatus } from './types';

export default function StatusBox({
  status,
  date,
  title,
  dateTitle,
  label,
  headerSlot,
  footSlot,
  sx,
}: Readonly<{
  status: TStatus;
  date: string;
  title: string;
  dateTitle: string;
  label?: string;
  headerSlot?: ReactNode;
  footSlot?: ReactNode;
  sx?: SxProps;
}>) {
  const theme = useTheme();
  const color = getColorByName(STATUS_COLOR_AND_ICON_MAPPING[status].colorName)?.(theme);

  return (
    <FlexPaper
      sx={{
        border: `${tokens.spacing3xs} solid ${color.borderColor ?? color.fillColor}`,
        padding: tokens.spacingBase,
        gap: tokens.spacingBase,
        ...sx,
      }}
    >
      <Stack flex={1} justifyContent="space-between">
        <Stack direction="row" gap={tokens.spacingXs} alignItems="center">
          <Typography>{title}</Typography>
          {headerSlot}
        </Stack>
        <Box sx={{ alignSelf: 'center' }}>
          <StatusChip status={status} label={label} size="medium" />
        </Box>
        <Stack direction="row" alignItems="end" justifyContent="center">
          <Box sx={{ flex: 2 }}>
            <NameValueDisplay
              name={dateTitle}
              value={formatToDDMMMYYYYHHMMAAA(date)}
              gap={tokens.spacing3xs}
            />
          </Box>
          {footSlot && <Stack>{footSlot}</Stack>}
        </Stack>
      </Stack>
    </FlexPaper>
  );
}
